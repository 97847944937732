@charset "UTF-8"
@import 'src/ui/styles/vars'

*
  box-sizing: border-box
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box

html
  height: 100%
  font-size: 100%
  overflow: hidden
  -webkit-tap-highlight-color: transparent
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  text-size-adjust: 100%

body
  margin: 0
  height: 100%
  background-color: $colorWhite
  color: $colorText
  font-family: $ffSerif
  font-size: $fsS
  line-height: normal
  -webkit-font-smoothing: antialiased
  font-smoothing: antialiased

input:focus,
textarea:focus
  outline: none

textarea
  resize: vertical

img
  border: 0
  -ms-interpolation-mode: bicubic
  vertical-align: middle

video
  display: inline-block

h1,
h2,
h3,
h4,
h5,
h6
  margin: 1em 0
  font-weight: bold

h1
  font-size: 40px
  line-height: 54px

h2
  font-size: 36px
  line-height: 40px

h3
  font-size: 24px
  line-height: 32px

p
  margin: 8px 0
  &:first-child
    margin-top: 0

ul,
ol
  margin: 0
  padding: 0
  list-style: none

dl,
dt,
dd,
figure
  margin: 0
  padding: 0

li
  margin: 0

table
  border-collapse: collapse
  border-spacing: 0

td
  vertical-align: top
  padding: 0

th
  text-align: left

blockquote
  margin: 18px 40px

pre,
code,
kbd,
samp
  font-family: monospace
  font-size: 1em

pre
  white-space: pre
  white-space: pre-wrap
  word-wrap: break-word

q
  quotes: none

q:before
  content: "\""
  position: absolute
  left: -5px

q:after
  content: "\""

sub,
sup
  line-height: 0
  position: relative
  vertical-align: baseline

sup
  top: -0.65em

sub
  bottom: -0.25em

form
  margin: 0

fieldset
  border: 0
  margin: 0
  padding: 0

label
  display: inline-block
  cursor: pointer
  font-weight: normal
  margin: 0

hr
  display: block
  height: 0
  border: 0
  border-top: 1px solid $colorBorder
  margin: 10px 0
  padding: 0
  clear: both

button,
input,
select,
textarea
  color: $colorText
  font-family: $ffSerif
  font-size: $fsS
  line-height: normal

button
  cursor: pointer

\::-webkit-input-placeholder
  color: $colorInputPlaceholder

\::-moz-placeholder
  color: $colorInputPlaceholder

\:-moz-placeholder
  color: $colorInputPlaceholder

\:-ms-input-placeholder
  color: $colorInputPlaceholder

input::placeholder
  color: $colorInputPlaceholder
