@import ../../../styles/vars

.messageCover
  position: absolute
  z-index: 2
  left: 0
  top: 0
  width: 100%
  height: 100%
  background-color: transparent
  opacity: .9
  &.__loading
    z-index: 2
  &.__clickable
    cursor: pointer

.messageCover_container
  position: relative
  top: 50%
  transform: translateY(-50%)
  max-width: 100%
  margin: auto

.messageCover_caption
  color: $colorLink
  text-align: center
  font-size: $fsM
  font-weight: bold
  margin-bottom: 10px
  &:empty
    display: none

.messageCover_caption.__text
  font-weight: normal

.messageCover.__error
  z-index: 2
  .messageCover_caption
    color: $colorRed

.messageCover_dots
  text-align: center
