.navigationPanel
  width: 100px

  flex-shrink: 0

  display: flex
  flex-direction: column

  background: #191919

  @media (max-width: 820px)
    width: 100%
    flex-direction: row

.mainLogo
  padding: 30px 15px 0

.navigationTabs
  height: 100%

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  @media (max-width: 820px)
    flex-direction: row
    margin-left: auto

.active
  background: #292A39

  @media (max-width: 820px)
    background: none

.navigationTab
  width: 100%
  height: 90px

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  text-align: center
  text-decoration: none

  @media (max-width: 820px)
    margin-right: 33px

    &:last-child
      margin-right: 10px

.navigationTab_title
  margin-top: 7px
  color: #fff

  @media (max-width: 820px)
    display: none

.inactiveTitle
  color: #a3a3a3
