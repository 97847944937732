// UI KIT
$ffSerif: "Open Sans", sans-serif
$fsXS: 11px
$fsS: 12px
$fsM: 14px
$fsL: 16px
$fsXXXL: 24px


$colorActive: #178FDC
$colorTextMuted: #5D717A
$colorWhite: #FFFFFF
$colorText: #111314
$colorBorder: #cccccc
$colorInputPlaceholder: #BCC7CC
$colorLink: #4488CC
$colorRed: #DC2B22
$colorMuted: #787878